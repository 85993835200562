import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface CepResponse {
  cep: string;
  street: string;
  city: string;
  state: string;
  neighborhood: string;
}

@Injectable({
  providedIn: 'root',
})
export class CepService {
  private apiUrl = 'https://brasilapi.com.br/api/cep/v1';

  constructor(private http: HttpClient) {}

  fetchAddressByCep(cep: string): Observable<CepResponse> {
    return this.http.get<CepResponse>(`${this.apiUrl}/${cep}`).pipe(
      catchError(error => {
        console.error('Erro ao buscar CEP:', error.message || error);
        return throwError(() => new Error('CEP não encontrado.'));
      })
    );
  }
}
