import { Routes } from '@angular/router';
import { CheckoutComponent } from './pages/checkout/checkout.component';

export const routes: Routes = [
  { path: '', component: CheckoutComponent },
  { path: 'checkout', component: CheckoutComponent },

  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: '**', redirectTo: 'login' }, // 404
];
