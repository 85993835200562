<button
  [class]="
    className ||
    '' +
      ' inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:pointer-events-none disabled:opacity-70'
  "
  [ngClass]="{
    'bg-blue-500': color === 'primary',
    'bg-gray-300': color === 'secondary',
    'bg-black400 text-gray-50 hover:bg-black500': color === 'black',
    'text-[16px] font-medium h-10 rounded-md px-8': size === 'medium',
    'bg-green300 text-white hover:bg-green400': color === 'green',
    'bg-red200 text-red300 border border-solid border-red300 hover:bg-red300 hover:text-white':
      color === 'outline-red',
    'bg-red300 text-primary-foreground hover:bg-red400': color === 'default',

    'text-[16px] font-medium h-[40px] rounded-[8px] px-8': size === 'medium',
    'text-xl font-medium h-[41px] rounded px-8': size === 'large',
    'text-md h-10 rounded-md px-4': size === 'small',
  }"
  type="{{ type }}">
  <ng-content></ng-content>
</button>
