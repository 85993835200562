import { Component, LOCALE_ID } from '@angular/core';
import { InputComponent } from '../../components/ui/input/input.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonComponent } from '../../components/ui/button/button.component';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { AccordionModule } from 'primeng/accordion';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CepResponse, CepService } from '../../services/cep.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmationService, MessageService } from 'primeng/api';

interface Installments {
  name: string;
  number: string;
}
interface Delivery {
  name: 'sedex' | 'pac';
  value: number;
  days: number;
}

interface Product {
  name: string;
  value: number;
  img: string;
}

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    CheckboxModule,
    ButtonComponent,
    ReactiveFormsModule,
    DropdownModule,
    TooltipModule,
    ConfirmDialogModule,
    ToastModule,
    AccordionModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    ConfirmationService,
    MessageService,
  ],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss',
})
export class CheckoutComponent {
  form: FormGroup;
  selectedPaymentMethod: string | null = null;
  selectedShippingMethod: string | null = null;
  currentStep = 1;
  isFetchingCep = false;
  installments: Installments[] | undefined;
  cep = '-';
  deliveryData: Delivery[] | undefined;
  productData: Product[] | undefined;

  apiUrl = 'https://api-checkout.virtu.srv.br/api';
  // apiUrl = 'https://localhost:7053/api';

  constructor(
    private fb: NonNullableFormBuilder,
    private cepService: CepService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private httpClient: HttpClient
  ) {
    this.installments = [
      { name: '1x de R$ 150,00', number: '1' },
      { name: '2x de R$ 75,00', number: '2' },
      { name: '3x de R$ 39,00', number: '3' },
      { name: '4x de R$ 22,00', number: '4' },
      { name: '5x de R$ 15,00', number: '5' },
    ];
    this.deliveryData = [
      { name: 'sedex', value: 25.99, days: 2 },
      { name: 'pac', value: 0, days: 8 },
    ];
    this.productData = [
      {
        name: 'MONEYBAGZ MURDERPANTS por GUGGIMON 1',
        value: 1500,
        img: 'assets/images/product.png',
      },
      {
        name: 'MONEYBAGZ MURDERPANTS por GUGGIMON 2',
        value: 175.99,
        img: 'assets/images/product.png',
      },
    ];

    this.form = this.fb.group({
      productId: [null],
      step1: this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        newsletter: [false],
      }),
      step2: this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        street: ['', Validators.required],
        state: ['', [Validators.required, Validators.pattern('^[A-Za-z]{2}$')]],
        city: ['', Validators.required],
        cep: [
          '',
          [Validators.required, Validators.pattern('[0-9]{5}-[0-9]{3}')],
        ],
        phone: [
          '',
          [
            Validators.required,
            Validators.pattern(/^\(\d{2}\) \d{4,5}-\d{4}$/),
          ],
        ],
        billingAddress: [false],
      }),
      step3: this.fb.group({
        shippingMethod: ['', Validators.required],
      }),
      step4: this.fb.group({
        paymentMethod: ['', Validators.required],
        cardNumber: [
          '',
          [Validators.required, Validators.pattern(/^\d{13,19}$/)],
        ],
        expiryDate: [
          '',
          [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])\/\d{2}$/)],
        ],
        cvv: ['', [Validators.required, Validators.pattern(/^\d{3,4}$/)]],
        cardHolderName: ['', Validators.required],
        cardHolderCpf: ['', [Validators.required]],
        selectedInstallment: new FormControl<Installments | null>(null),
        billingAddress: [false],
      }),
    });
  }

  // CRIADO APENAS PARA FINS DE TESTES...
  searchProduct() {

    this.httpClient.post(`${this.apiUrl}/carts`, {
        CartProductId: this.form.get('productId')?.value,
        SessionId: sessionStorage.getItem('session_id'),
      })
      .subscribe({
        next: (data: any) => {
          console.log(data);
          if (data?.sessionId) {
            sessionStorage.setItem('session_id', data.sessionId);
          }
        },
        error: () => {},
      });

  }

  nextStep() {
    console.log(this.form.value);
    if (this.isStepValid(this.currentStep)) {
      this.currentStep++;
      console.log(this.currentStep);
    } else if (this.currentStep === 3) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção',
        detail: 'Selecione um método de envio!',
      });
      return;
    } else if (this.currentStep === 4 && !this.selectedPaymentMethod) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção',
        detail: 'Selecione um método de pagamento!',
      });
      return;
    } else if (this.currentStep === 4 && this.selectedPaymentMethod) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção',
        detail: 'Preencha todos os campos do cartão de crédito!',
      });
      return;
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção',
        detail: 'Preencha todos os campos!',
      });
    }
  }

  isStepValid(step: number): boolean {
    const groupName = `step${step}`;
    const group = this.form.get(groupName) as FormGroup;
    return group?.valid || false;
  }

  validateStepFields(step: number): void {
    for (let i = 1; i <= step; i++) {
      const groupName = `step${i}`;
      const group = this.form.get(groupName) as FormGroup;
      if (group) {
        Object.values(group.controls).forEach(control =>
          control.markAsTouched()
        );
      }
    }
  }

  submit(): void {
    this.validateStepFields(this.currentStep);

    if (this.isStepValid(this.currentStep)) {
      console.log('Form submitted', this.form.value);
    } else {
      console.log('Form has errors');
    }
  }

  onCepChange(event: any) {
    const cep = (event.target as HTMLInputElement).value;

    if (cep.length === 9 && !this.isFetchingCep) {
      console.log('CEP completo:', cep);

      this.cep = cep;

      this.isFetchingCep = true;

      this.cepService.fetchAddressByCep(cep).subscribe({
        next: (data: CepResponse) => {
          const formattedCep = this.formatCep(data.cep);

          this.form.get('step2')?.patchValue({
            cep: formattedCep,
            state: data.state,
            city: data.city,
            street: data.street,
          });

          this.isFetchingCep = false;

          console.log(this.form.value);
        },
        error: (err: HttpErrorResponse) => {
          // alert(err.message);

          this.isFetchingCep = false;
        },
      });
    }
  }

  formatCep(cep: string): string {
    return cep.replace(/^(\d{5})(\d{3})$/, '$1-$2');
  }

  selectShippingMethod(method: 'sedex' | 'pac'): void {
    this.selectedShippingMethod = method;
  }

  selectPaymentMethod(method: 'pix' | 'boleto' | 'credit'): void {
    this.selectedPaymentMethod = method;
    this.updateCardFieldsValidation(method);
  }

  updateCardFieldsValidation(paymentMethod: string | null): void {
    const step4Group = this.form.get('step4') as FormGroup;

    if (paymentMethod === 'credit') {
      // Torna os campos obrigatórios
      step4Group
        .get('cardNumber')
        ?.setValidators([
          Validators.required,
          Validators.pattern(/^\d{13,19}$/),
        ]);
      step4Group
        .get('expiryDate')
        ?.setValidators([
          Validators.required,
          Validators.pattern(/^(0[1-9]|1[0-2])\/\d{2}$/),
        ]);
      step4Group
        .get('cvv')
        ?.setValidators([Validators.required, Validators.pattern(/^\d{3,4}$/)]);
      step4Group.get('cardHolderName')?.setValidators(Validators.required);
      step4Group.get('cardHolderCpf')?.setValidators(Validators.required);
      step4Group.get('selectedInstallment')?.setValidators(Validators.required);
    } else {
      // Remove as validações de obrigatoriedade se não for cartão de crédito
      step4Group.get('cardNumber')?.clearValidators();
      step4Group.get('expiryDate')?.clearValidators();
      step4Group.get('cvv')?.clearValidators();
      step4Group.get('cardHolderName')?.clearValidators();
      step4Group.get('cardHolderCpf')?.clearValidators();
      step4Group.get('selectedInstallment')?.clearValidators();
    }

    // Atualiza o estado de validação do formulário após modificar as validações
    step4Group.get('cardNumber')?.updateValueAndValidity();
    step4Group.get('expiryDate')?.updateValueAndValidity();
    step4Group.get('cvv')?.updateValueAndValidity();
    step4Group.get('cardHolderName')?.updateValueAndValidity();
    step4Group.get('cardHolderCpf')?.updateValueAndValidity();
    step4Group.get('selectedInstallment')?.updateValueAndValidity();
  }

  getSubtotal(): number {
    return (
      this.productData?.reduce((total, product) => total + product.value, 0) ||
      0
    );
  }

  getShipping(): number {
    return (
      this.deliveryData?.find(
        delivery => delivery.name === this.selectedShippingMethod
      )?.value || 0
    );
  }

  getTotalWithShipping(): number {
    const subtotal = this.getSubtotal();
    const shipping = this.getShipping();

    return subtotal + shipping;
  }

  confirmRemoveProduct(index: number) {
    this.confirmationService.confirm({
      message: 'O item será removido do seu carrinho',
      header: 'Você tem certeza que deseja remover este item?',
      icon: 'pi pi-info-circle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptButtonStyleClass: 'p-button-success',
      rejectButtonStyleClass: 'p-button-cancel',
      accept: () => {
        this.removeProduct(index);
      },
    });
  }

  // Função para remover o produto do array
  removeProduct(index: number) {
    this.productData?.splice(index, 1);
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Produto removido do carrinho',
    });
    console.log(this.productData);
  }
}
