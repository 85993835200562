<div class="relative">
  <input
    [class]="className"
    [ngClass]="{
      'bg-blue-500': color === 'primary',
      'bg-gray-red': color === 'secondary',
      'bg-white': color === 'white',
      'bg-white300 border placeholder-grayBlue300 border-solid border-gray200 dark:placeholder-gray300 dark:bg-black300 dark:border-black200 dark:text-white':
        color === 'default',

      'border-[1px] border-solid border-gray-300 text-gray-600 rounded-lg':
        style === 'default',

      'h-16': size === 'medium',
      'h-12': size === 'small',
      'rounded-md text-base px-3 outline-none': true,
    }"
    [type]="type"
    [placeholder]="placeholder"
    [value]="value"
    (input)="onInput($event)"
    (blur)="onBlur()"
    [disabled]="isDisabled"
    [attr.maxlength]="maxlength"
    [attr.minlength]="minlength"
    [mask]="mask" />

  <div
    *ngIf="iconName"
    class="absolute top-[50%] right-3 translate-y-[-50%] w-5 h-5 flex items-center justify-center"
    [pTooltip]="pTooltipIcon"
    [tooltipPosition]="tooltipPositionIcon">
    <i [class]="'pi ' + iconName" style="color: black"></i>
  </div>
</div>
