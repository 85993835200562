import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [CommonModule, NgxMaskDirective, ReactiveFormsModule, TooltipModule],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    provideNgxMask(),
  ],
})
export class InputComponent implements ControlValueAccessor {
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() color: 'default' | 'primary' | 'secondary' | 'white' = 'default';
  @Input() style: 'default' | 'primary' | 'secondary' = 'default';
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() maxlength = '';
  @Input() minlength = '';
  @Input() className = '';
  @Input() mask = '';
  @Input() iconName = '';
  @Input() pTooltipIcon = '';
  @Input() tooltipPositionIcon = '';

  value = '';
  isDisabled = false;

  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(value: string): void {
    this.value = value || '';
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.value = input.value;
    this.onChange(this.value); // Atualiza o valor
  }

  onBlur(): void {
    this.onTouched(); // Marca como "tocado" apenas no blur
  }
}
